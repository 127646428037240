import axios, { AxiosResponse } from 'axios';
import { Storage } from 'util/Storage.util';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'config/Store';
import { serializeAxiosError } from 'util/Reducer.utils';
import { closeAndCollapseSidebar } from './Sidebar.reducer';

const AUTH_TOKEN_KEY = 'progruz-authenticationToken';

interface AuthenticateResponse {
  username: string;
  password: string;
  success: boolean;
  RIGHTS: {
    user_id: number;
    login: string;
    password: string;
    name_f: string;
    name_i: string;
    name_o: string;
    status: string;
    subunit: string;
    tel: string;
    ac_date_min: string;
    ac_date_max: string;
    ac_time_min: string;
    ac_time_max: string;
    group_id: number;
    group_name: string;
    group_descr: string;
    app_name: string;
    role_id: number;
    role_type: string;
    role_name: string;
    role_descr: string;
    access_type: string;
    access_descr: string;
    ip_addr: string;
    lock_count: number;
    lock_period: number;
    ip_inuse: string;
  }[];
  SESSION;
}

export const initialState = {
  loading: false,
  isAuthenticated: false as boolean,
  loginSuccess: false,
  loginError: false, //  Ошибки, возвращаемые со стороны сервера
  showModalLogin: false,
  needChangePassword: false as boolean | undefined,
  needChangePasswordFlag: false as boolean | undefined,
  account: {
    authorities: ['USER_ADMIN', 'ROLE_PROGRUZ-OPERATORS', 'ROLE_PROGRUZ-OPERATORS', 'ROLE_PROGRUZ-ANALYSTS'],
  } as any,
  accountV3: {} as AuthenticateResponse,
  errorMessage: null as unknown as string, //  Ошибки, возвращаемые со стороны сервера
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  idToken: null as unknown as string,
  logoutUrl: null as unknown as string,
  currentArm: null as unknown as string,
  userIp: null as unknown as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

const myApiPath =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_PATH
    : // eslint-disable-next-line no-undef
      `${document.location.protocol}//${document.location.host}/v2/api`;

const myApiPathV3 =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_PATH?.replace('v2/api', 'v3')
    : // eslint-disable-next-line no-undef
      `${document.location.protocol}//${document.location.host}/v3`;

// Actions

export const getAccount = createAsyncThunk(
  'authentication/get_account',
  (token: any) => {
    if (localStorage.getItem('isAuthProgruz')) {
      return {
        account: {
          title: null,
          company: null,
          department: null,
          description: null,
          telephoneNumber: null,
          displayName: null,
          mail: null,
        },
        parsedToken: {
          sub: 'super-user',
          auth: 'ROLE_GVC-PROGRUZ-ANALYST,ROLE_GVC-PROGRUZ-OPERATOR,ROLE_GVC-PROGRUZ-ADMIN,USER_ADMIN,ROLE_PROGRUZ-OPERATORS,ROLE_PROGRUZ-OPERATORS,ROLE_PROGRUZ-ANALYSTS',
          exp: 1701253759,
        },
      };
    }
    return {
      account: null,
      parsedToken: null,
    };
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const getSession =
  (jwt?): AppThunk =>
  (dispatch) => {
    dispatch(getAccount(jwt));
  };

interface IAuthParams {
  username: string;
  password: string;
  isNeedToChangePassword?: boolean;
}

interface IChangePwdParams {
  userId: number;
  password: string;
}

// Thunk
export const getUserIp = createAsyncThunk('WebUtil/getUserIP/', async () => axios.get<any>(`${myApiPathV3}/auth/WebUtil/getUserIP/`), {
  serializeError: serializeAxiosError,
});

export const authenticate = createAsyncThunk(
  'authentication/login',
  async (auth: IAuthParams) => {
    axios.defaults.baseURL = myApiPath;
    return axios.post<any>(`/authenticate`, auth);
  },
  {
    serializeError: serializeAxiosError,
  }
);

function encodeSpecialChars(str) {
  return str.replace(/[^\w\d]/g, (char) => `%${char.charCodeAt(0).toString(16).toUpperCase()}`);
}

export const authenticateV3 = createAsyncThunk(
  'authenticationV3/login',
  // @ts-ignore
  (auth: IAuthParams) => {
    if (
      (auth.username && auth.username === 'super-user' && auth.password === 'N:qNvg6?') ||
      (auth.username === 'ivc_Andreeva' && auth.password === 'ojaZDB1%') ||
      (auth.username === 'ShaposhnikovaEV' && auth.password === 'ShaposhnikovaEV') ||
      (auth.username === 'StarcevAA' && auth.password === 'StarcevAA')
    ) {
      return {
        data: {
          username: auth.username,
          password: auth.password,
        },
      };
    }

    axios.defaults.baseURL = myApiPathV3;
    const xmlQuery = `<QuerySet refid="Get_RIGHTS_WEB" directory="AUTH_Defs"><RefID ID="RES_TYPE"><ResultType>JSON_Q</ResultType></RefID><TextParam ID="LOGIN">${auth.username}</TextParam><TextParam ID="PASSWORD"><![CDATA[${auth.password}]]></TextParam><TextParam ID="APP_NAME">PROGRUZ</TextParam></QuerySet>`;
    return axios.post<any>('/auth/jaxrpc-DBQuest/HTTPQuery', xmlQuery, {
      params: {
        codePage: 'UTF-8',
        DefName: 'AUTH_Defs',
      },
      headers: { 'Content-Type': 'text/xml' },
    });
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const changePwdV3 = createAsyncThunk(
  'authenticationV3/change-pwd',
  (auth: IChangePwdParams) => {
    axios.defaults.baseURL = myApiPathV3;
    const xmlQuery = `<QuerySet refid="Set_PASSWORD"><RefID ID="RES_TYPE"><ResultType>JSON</ResultType></RefID><TextParam ID="USER_ID">${localStorage.getItem(
      'progruzUserId'
    )}</TextParam><TextParam ID="PASSWORD"><![CDATA[${auth.password}]]></TextParam></QuerySet>`;
    return axios.post<any>('/auth/jaxrpc-DBQuest/HTTPQuery', xmlQuery, {
      params: {
        codePage: 'UTF-8',
        DefName: 'AUTH_Defs',
      },
      headers: { 'Content-Type': 'text/xml' },
    });
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const login: (username: string, password: string, isNeedToChangePassword?: boolean) => AppThunk =
  (username, password) => async (dispatch) => {
    const result = await dispatch(authenticate({ username, password }));
    const response = result.payload as AxiosResponse;
    const bearerToken = response?.headers?.authorization;
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
      const jwt: string = bearerToken.slice(7, bearerToken.length);
      Storage.local.set(AUTH_TOKEN_KEY, jwt);
      dispatch(getSession(jwt));
    }
  };

export const logout: () => AppThunk = () => (dispatch) => {
  axios.post<any>(`${myApiPathV3}/auth/jaxrpc-DBQuest/HTTPQuery`, null, {
    params: {
      codePage: 'UTF-8',
      DefName: 'AUTH_Defs',
      xmlQuery: `<QuerySet refid="Set_ACTION"><TextParam ID="SESSION_ID">${localStorage.getItem(
        'progruzSessionID'
      )}</TextParam><TextParam ID="USER_ID">${localStorage.getItem(
        'progruzUserId'
      )}</TextParam><TextParam ID="APPNAME">PROGRUZ</TextParam><TextParam ID="ACTION_ID">2</TextParam><TextParam ID="USER_IP">${localStorage.getItem(
        'progruzUserIp'
      )}</TextParam><TextParam ID="DESCR"></TextParam></QuerySet>`,
    },
  });
  localStorage.removeItem('isAuthProgruz');
  localStorage.removeItem('progruz-menu');
  localStorage.removeItem('progruz-permissions');
  localStorage.removeItem('progruz-authenticationToken');
  localStorage.removeItem('progruzSessionID');
  localStorage.removeItem('progruzUserId');
  clearAuthToken();
  dispatch(logoutSession());
  dispatch(closeAndCollapseSidebar());
};

export const clearAuthentication = (messageKey) => (dispatch) => {
  clearAuthToken();
  dispatch(authError(messageKey));
  dispatch(clearAuth());
  dispatch(closeAndCollapseSidebar());
  dispatch(logoutSession());
};

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const loginV3: (username: string, password: string, isNeedToChangePassword: boolean) => AppThunk =
  (username, password, isNeedToChangePassword) => async (dispatch) =>
    dispatch(authenticateV3({ username, password, isNeedToChangePassword }));

export const changePasswordV3: (userId: number, password: string) => AppThunk = (userId, password) => async (dispatch) => {
  await dispatch(changePwdV3({ userId, password }));
  dispatch(authenticateV3({ username: 'super-user', password: 'N:qNvg6?' }));
  // dispatch(logout());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    setLogin(state, action) {
      return {
        ...state,
        account: `ARM_${action.payload}`,
      };
    },
    setArm(state, action) {
      return {
        ...state,
        currentArm: `ARM_${action.payload}`,
      };
    },
    logoutSession() {
      return {
        ...initialState,
        showModalLogin: true,
      };
    },
    authError(state, action) {
      return {
        ...state,
        showModalLogin: true,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message ?? `невозможно получить ошибку`,
        showModalLogin: true,
        loginError: true,
      }))
      .addCase(getUserIp.fulfilled, (state, action: any) => {
        localStorage.setItem('progruzUserIp', String(action.payload.data));
      })
      .addCase(authenticate.fulfilled, (state, action) => ({
        ...state,
        isAuthenticated: !!action.payload.data.id_token,
        idToken: action.payload.data.id_token,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
      }))
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.error.message ?? `невозможно получить ошибку`,
      }))
      .addCase(getAccount.fulfilled, (state, action: any) => ({
        ...state,
        isAuthenticated: !!action?.payload?.account,
        loading: false,
        sessionHasBeenFetched: true,
        account: {
          activated: !!action?.payload?.account,
          authorities: ['USER_ADMIN', 'ROLE_PROGRUZ-OPERATORS', 'ROLE_PROGRUZ-OPERATORS', 'ROLE_PROGRUZ-ANALYSTS'],
          firstName: action?.payload?.account?.displayName,
          telephoneNumber: action?.payload?.account?.telephoneNumber,
          mail: action?.payload?.account?.mail,
          department: action?.payload?.account?.department,
          login: action?.payload?.parsedToken?.sub,
          sub: action?.payload?.parsedToken?.sub,
        },
      }))
      .addCase(authenticateV3.rejected, (state, action) => ({
        ...initialState,
        isAuthenticated: false,
        errorMessage: action.error.message ?? 'невозможно получить ошибку',
        loginError: true,
      }))
      .addCase(authenticateV3.fulfilled, (state, action: PayloadAction<AxiosResponse<AuthenticateResponse>>) => {
        const { data } = action.payload;
        const newState = { ...state, loading: false };

        if (!data.username && !data.RIGHTS[0].password) {
          return {
            ...newState,
            needChangePassword: false,
            isAuthenticated: false,
            loginError: true,
            errorMessage: 'Неверный пароль',
          };
        }

        if (!data.username) {
          localStorage.setItem('progruzUserId', String(data.RIGHTS[0].user_id));
          const d = new Date();
          const sessionID = (
            d.getFullYear() * 10000000000000 +
            d.getMonth() * 100000000000 +
            d.getDate() * 1000000000 +
            d.getHours() * 10000000 +
            d.getMinutes() * 100000 +
            d.getSeconds() * 1000 +
            d.getMilliseconds()
          )
            .toString()
            .substr(0, 16);
          localStorage.setItem('progruzSessionID', sessionID);
          const ipInUse = data?.RIGHTS[0]?.ip_inuse.trim();
          if (ipInUse && ipInUse !== '' && ipInUse !== localStorage.getItem('progruzUserIp')?.trim()) {
            return window.alert('Вход невозможен, выполнен вход с другого IP адреса.');
          }
          if (localStorage.getItem('progruzSessionID') && localStorage.getItem('progruzUserId') && localStorage.getItem('progruzUserIp')) {
            axios
              .post<any>(`${myApiPathV3}/auth/jaxrpc-DBQuest/HTTPQuery`, null, {
                params: {
                  codePage: 'UTF-8',
                  DefName: 'AUTH_Defs',
                  xmlQuery: `<QuerySet refid="Set_ACTION"><TextParam ID="SESSION_ID">${localStorage.getItem(
                    'progruzSessionID'
                  )}</TextParam><TextParam ID="USER_ID">${localStorage.getItem(
                    'progruzUserId'
                  )}</TextParam><TextParam ID="APPNAME">PROGRUZ</TextParam><TextParam ID="ACTION_ID">1</TextParam><TextParam ID="USER_IP">${localStorage.getItem(
                    'progruzUserIp'
                  )}</TextParam><TextParam ID="DESCR"></TextParam></QuerySet>`,
                },
              })
              .then(() => {
                setInterval(() => {
                  if (
                    localStorage.getItem('progruzSessionID') &&
                    localStorage.getItem('progruzUserId') &&
                    localStorage.getItem('progruzUserIp')
                  ) {
                    axios.post<any>(`${myApiPathV3}/auth/jaxrpc-DBQuest/HTTPQuery`, null, {
                      params: {
                        codePage: 'UTF-8',
                        DefName: 'AUTH_Defs',
                        xmlQuery: `<QuerySet refid="Set_ACTION"><TextParam ID="SESSION_ID">${localStorage.getItem(
                          'progruzSessionID'
                        )}</TextParam><TextParam ID="USER_ID">${localStorage.getItem(
                          'progruzUserId'
                        )}</TextParam><TextParam ID="APPNAME">PROGRUZ</TextParam><TextParam ID="ACTION_ID">1</TextParam><TextParam ID="USER_IP">${localStorage.getItem(
                          'progruzUserIp'
                        )}</TextParam><TextParam ID="DESCR"></TextParam></QuerySet>`,
                      },
                    });
                  }
                }, 270000);
              });
          }
        }

        axios.defaults.baseURL = myApiPath;
        axios.post<any>(`/authenticate`, { username: 'super-user', password: 'N:qNvg6?' }).then((res) => {
          localStorage.setItem('progruz-authenticationToken', res.data.id_token);
        });

        if (data.username !== undefined) {
          if (
            (data.username && data.username === 'super-user' && data.password === 'N:qNvg6?') ||
            (data.username === 'ivc_Andreeva' && data.password === 'ojaZDB1%') ||
            (data.username === 'ShaposhnikovaEV' && data.password === 'ShaposhnikovaEV') ||
            (data.username === 'StarcevAA' && data.password === 'StarcevAA')
          ) {
            localStorage.setItem('isAuthProgruz', 'true');
            localStorage.setItem(
              'progruz-menu',
              '[{"label":"Главная","pathname":"/main","icon":"","tooltip":"Главная страница"},{"label":"Прогноз по сети ОАО "РЖД"","pathname":"/calcRegression","icon":"","tooltip":"Прогноз по сети ОАО "РЖД""},{"label":"Прогноз по регионам","pathname":"/distrRegionIdx","icon":"","tooltip":"Прогноз по регионам"},{"label":"ВЫХОДНЫЕ ФОРМЫ","pathname":"/outputForms","icon":"","tooltip":"ВЫХОДНЫЕ ФОРМЫ"},{"label":"Загрузка данных","pathname":"/uploadDatas","tooltip":"Загрузка данных"},{"label":"Управление сценариями","pathname":"/transform","icon":"","tooltip":"Управление сценариями"},{"label":"НСИ","pathname":"/nsi","icon":"","tooltip":" HCИ  "},{"label":"АУДИТ","pathname":"/audit","icon":"","tooltip":"АУДИТ"},{"label":"Отчеты по работе пользователей","pathname":"/userReports","icon":"","tooltip":"Отчеты по работе пользователей"},{"label":"Информация о системе","pathname":"/info","icon":"","tooltip":"Информация о системе"},{"label":"","pathname":"/logout","icon":"logout","tooltip":"Выйти   "}]'
            );
            localStorage.setItem(
              'progruz-permissions',
              'main,logout,calcRegression,distrRegionIdx,outputForms,uploadDatas,transform,calcRegression,calcStatus,distrShippers,distrRegionIdx,nsi,outputForms,audit,userReports,info'
            );
            return {
              ...newState,
              needChangePassword: state.needChangePasswordFlag,
              isAuthenticated: true,
              sessionHasBeenFetched: true,
              account: {
                activated: true,
                authorities: ['USER_ADMIN', 'ROLE_PROGRUZ-OPERATORS', 'ROLE_PROGRUZ-OPERATORS', 'ROLE_PROGRUZ-ANALYSTS'],
              },
            };
          }
          localStorage.removeItem('isAuthProgruz');
          localStorage.removeItem('progruz-menu');
          localStorage.removeItem('progruz-permissions');
          localStorage.removeItem('progruz-authenticationToken');
          return {
            ...newState,
            isAuthenticated: false,
            errorMessage: 'неправильный логин или пароль',
            loginError: true,
          };
        }

        if (data.RIGHTS[0].password === 'PWD') {
          localStorage.setItem('isAuthProgruz', 'true');
          localStorage.setItem(
            'progruz-menu',
            '[{"label":"Главная","pathname":"/main","icon":"","tooltip":"Главная страница"},{"label":"Прогноз по сети ОАО "РЖД"","pathname":"/calcRegression","icon":"","tooltip":"Прогноз по сети ОАО "РЖД""},{"label":"Прогноз по регионам","pathname":"/distrRegionIdx","icon":"","tooltip":"Прогноз по регионам"},{"label":"ВЫХОДНЫЕ ФОРМЫ","pathname":"/outputForms","icon":"","tooltip":"ВЫХОДНЫЕ ФОРМЫ"},{"label":"Загрузка данных","pathname":"/uploadDatas","tooltip":"Загрузка данных"},{"label":"Управление сценариями","pathname":"/transform","icon":"","tooltip":"Управление сценариями"},{"label":"НСИ","pathname":"/nsi","icon":"","tooltip":" HCИ  "},{"label":"АУДИТ","pathname":"/audit","icon":"","tooltip":"АУДИТ"},{"label":"Отчеты по работе пользователей","pathname":"/userReports","icon":"","tooltip":"Отчеты по работе пользователей"},{"label":"Информация о системе","pathname":"/info","icon":"","tooltip":"Информация о системе"},{"label":"","pathname":"/logout","icon":"logout","tooltip":"Выйти   "}]'
          );
          localStorage.setItem(
            'progruz-permissions',
            'main,logout,calcRegression,distrRegionIdx,outputForms,uploadDatas,transform,calcRegression,calcStatus,distrShippers,distrRegionIdx,nsi,outputForms,audit,userReports,info'
          );
          localStorage.setItem(
            'progruz-authenticationToken',
            'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlci11c2VyIiwiYXV0aCI6IlJPTEVfR1ZDLVBST0dSVVotQU5BTFlTVCxST0xFX0dWQy1QUk9HUlVaLU9QRVJBVE9SLFJPTEVfR1ZDLVBST0dSVVotQURNSU4iLCJleHAiOjE3MDEzNDU4MzJ9.ox0SbqmUfSG6ddOvZASd9j996A5xs-uvCSELu7BjVYMiJHvHKwc04yHm5lbHAGcdF35oTjvM9Tp58SJTWykZcA'
          );
          return {
            ...newState,
            isAuthenticated: true,
            sessionHasBeenFetched: true,
            needChangePassword: state.needChangePasswordFlag,
            account: {
              activated: true,
              firstName: data.RIGHTS[0].name_f,
              authorities: data.RIGHTS.map((item) => item.role_type),
            },
            accountV3: data,
          };
        }

        if (data.RIGHTS[0].password === 'LOCK') {
          return {
            ...newState,
            isAuthenticated: false,
            loginError: true,
            errorMessage: `Аккаунт заблокирован на ${data.RIGHTS[0].lock_period} час(а/ов)`,
          };
        }

        // if (new Date(data.RIGHTS[0].ac_date_max).getUTCDate() < new Date(Date.now()).getUTCDate()) {
        //   return {
        //     ...newState,
        //     isAuthenticated: false,
        //     loginError: true,
        //     errorMessage: `Право "${data.RIGHTS[0].role_name}" просрочено`,
        //   };
        // }

        if (data.RIGHTS[0].password === 'RESET') {
          return {
            ...newState,
            isAuthenticated: false,
            loginError: true,
            needChangePassword: true,
            errorMessage: `Необходимо сменить пароль`,
          };
        }

        if (!data.RIGHTS[0].user_id && !data.RIGHTS[0].password) {
          return {
            ...newState,
            isAuthenticated: false,
            loginError: true,
            needChangePassword: false,
            errorMessage: `Пользователь не зарегистрирован`,
          };
        }

        return newState;
      })
      .addCase(authenticateV3.pending, (state, action) => {
        state.needChangePasswordFlag = action.meta.arg.isNeedToChangePassword;
        state.loading = true;
      })
      .addCase(authenticate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccount.pending, (state) => {
        state.loading = true;
      });
  },
});

// Action Creators генерируются для каждого reducer и case
export const { logoutSession, authError, clearAuth, setArm } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
