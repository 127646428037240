import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'config/i18nConfig';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import deepOrange from '@material-ui/core/colors/deepOrange';
import SideBar from 'common/SideBar';
import Header from 'common/Header';
import ErrorBoundary from 'shared/error/ErrorBoundary';
import styles from 'App.module.css';
import AppRoutes from 'routes';
import { useAppDispatch, useAppSelector } from 'config/Store';
import { hasAnyAuthority } from 'shared/auth/PrivateRoute';
import { AUTHORITIES } from 'config/Constants';
import { getSession, getUserIp } from 'reducers/Authentication.reducer';
import { instance } from 'components/RootCode/axios';

const myApiPathV3 =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_PATH?.replace('v2/api', 'v3')
    : // eslint-disable-next-line no-undef
      `${document.location.protocol}//${document.location.host}/v3`;

const App = () => {
  const [info, setInfo] = useState({ frontendTag: '', frontendDate: '', backendTag: '', backendDate: '' });

  // TODO Подумать над автоматическим получением данных о пользователе при загрузке приложения
  const dispatch = useAppDispatch();

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: deepOrange,
    },
  });

  const parseDate = (date) => new Date(date).toLocaleDateString();

  const getInfo = async () => {
    const res = await fetch(`${window.location.origin}/v2/version.json`);
    const information = await res.json();
    setInfo((prev) => ({ ...prev, frontendTag: information.TAG, frontendDate: parseDate(information.CI_JOB_STARTED_AT) }));
  };

  useEffect(() => {
    if (!window.location.href.includes('dev-progruz-web')) {
      dispatch(getUserIp());
    }
    // const token = getJwtToken();
    // if (token) {
    // dispatch(getSession(token));
    // }

    dispatch(getSession('token'));

    if (!window.location.hostname.includes('localhost')) {
      getInfo();
    } else {
      setInfo((prev) => ({ ...prev, frontendTag: '1.0.29', frontendDate: parseDate('2023-04-27T14:21:11Z') }));
    }
    instance.get('/test/healthcheck').then((res: any) => {
      setInfo((prev) => ({ ...prev, backendTag: res.data.back_tag, backendDate: parseDate(res.data.release_date) }));
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem('progruzSessionID') && localStorage.getItem('progruzUserId') && localStorage.getItem('progruzUserIp')) {
      axios.post<any>(`${myApiPathV3}/auth/jaxrpc-DBQuest/HTTPQuery`, null, {
        params: {
          codePage: 'UTF-8',
          DefName: 'AUTH_Defs',
          xmlQuery: `<QuerySet refid="Set_ACTION"><TextParam ID="SESSION_ID">${localStorage.getItem(
            'progruzSessionID'
          )}</TextParam><TextParam ID="USER_ID">${localStorage.getItem(
            'progruzUserId'
          )}</TextParam><TextParam ID="APPNAME">PROGRUZ</TextParam><TextParam ID="ACTION_ID">2</TextParam><TextParam ID="USER_IP">${localStorage.getItem(
            'progruzUserIp'
          )}</TextParam><TextParam ID="DESCR"></TextParam></QuerySet>`,
        },
      });
      setInterval(() => {
        axios.post<any>(`${myApiPathV3}/auth/jaxrpc-DBQuest/HTTPQuery`, null, {
          params: {
            codePage: 'UTF-8',
            DefName: 'AUTH_Defs',
            xmlQuery: `<QuerySet refid="Set_ACTION"><TextParam ID="SESSION_ID">${localStorage.getItem(
              'progruzSessionID'
            )}</TextParam><TextParam ID="USER_ID">${localStorage.getItem(
              'progruzUserId'
            )}</TextParam><TextParam ID="APPNAME">PROGRUZ</TextParam><TextParam ID="ACTION_ID">2</TextParam><TextParam ID="USER_IP">${localStorage.getItem(
              'progruzUserIp'
            )}</TextParam><TextParam ID="DESCR"></TextParam></QuerySet>`,
          },
        });
      }, 270000);
    }
  }, [localStorage.getItem('progruzSessionID')]);

  const isAdmin = useAppSelector((state) => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN_OLD]));
  const isSideBarCollapsed = useAppSelector((state) => state.sidebar.isCollapsed);

  return (
    <Router basename="/v2">
      <div className={styles.mainContainer}>
        <ErrorBoundary>
          <SideBar info={info} />
        </ErrorBoundary>
        <div className={styles.routesContainer}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            closeButton
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>
          <ErrorBoundary>
            <ThemeProvider theme={defaultMaterialTheme}>
              <AppRoutes />
            </ThemeProvider>
          </ErrorBoundary>
        </div>
      </div>
    </Router>
  );
};

export default App;
